export const FOOTER_CONSTANTS = {
  aboutUs: 'https://www.vaultrd.com/about-us',
  strategy: [
    {
      text: 'Product Strategy Consulting',
      url: 'https://www.vaultrd.com/services/strategy/product-strategy-consulting',
    },
    {
      text: 'Digital Transformation',
      url: 'https://www.vaultrd.com/services/strategy/digital-transformation',
    },
    {
      text: 'Application Modernization',
      url: 'https://www.vaultrd.com/services/strategy/application-modernization-services',
    },
  ],

  productDesign: [
    {
      text: 'UX & Technical Audit',
      url: 'https://www.vaultrd.com/services/app-audit',
    },
    {
      text: 'User Experience',
      url: 'https://www.vaultrd.com/services/design/ux-ui-design',
    },
  ],
  engineering: [
    {
      text: 'Web Application Development',
      url: 'https://www.vaultrd.com/services/engineering/web-app-development',
    },
    {
      text: 'Mobile Application Development',
      url: 'https://www.vaultrd.com/services/engineering/mobile-app-development',
    },
    {
      text: 'Artificial Intelligence',
      url: 'https://www.vaultrd.com/services/engineering/artificial-intelligence-machine-learning',
    },
  ],

  social: {
    linkedIn: {
      text: 'LinkedIn',
      url: 'https://www.linkedin.com/company/vaultrd/',
    },
    email: {
      text: 'hello@vaultrd.com',
      url: 'mailto:hello@vaultrd.com',
    },
    phone: {
      text: '+1 (619) 618 - 0969',
      url: 'tel:+16196180969',
    },
    address: {
      text: `3919 30th Street, San Diego, California 92104`,
      url: 'http://maps.google.com/?q=3919 30th Street, San Diego, California 92104',
    },
  },

  privacy: {
    text: `Privacy Policy`,
    url: 'https://www.vaultrd.com/privacy-policy',
  },
  homePage: {
    url: 'https://www.vaultrd.com/',
  },
  copyright: '© 2024 Vault R&D, LLC.',
};
