import {
  FooterBackdrop,
  FooterContainer,
  FooterDividerLine,
  FooterLinksBottomContainer,
  FooterLinksButton,
  FooterLinksColumnContainer,
  FooterLinksContainer,
  FooterLinksGroup,
  FooterLogoImage,
  FooterSocialLinksButton,
  FooterSocialLinksContainer,
  FooterSocialLinksContent,
  FooterSocialLinksDesktopButton,
  FooterSocialLinksMobileButton,
} from '@/src/styles/footer-styles';
import React from 'react';
import { FOOTER_CONSTANTS as f } from './footer-constants';
import { FooterComedyMaskSvg, FooterLinkAnchor, FooterLinsksGroupHeaderText } from './FooterLink';
import LogoWhite from '../../assets/svg/LogoWhite.svg';
import { MdOutlineCall } from 'react-icons/md';
import { GoLocation } from 'react-icons/go';
import { MdMailOutline } from 'react-icons/md';
import { BsLinkedin } from 'react-icons/bs';
import { GlobalFooter } from '@/src/payload-types';
import { getPayloadUrl } from '@/src/payload/utils/client.utils';

interface IProps {
  footerData: GlobalFooter;
  style?: React.CSSProperties;
}

const Footer: React.FC<IProps> = ({ footerData, style }) => {
  return (
    <FooterBackdrop className={'footer-section'} style={style}>
      {footerData && (
        <FooterContainer>
          <FooterLinksContainer>
            <FooterLinksColumnContainer>
              <FooterLinksButton $buttonType={'primary'} href={'/about-us'}>
                ABOUT US
              </FooterLinksButton>
            </FooterLinksColumnContainer>
            <FooterLinksColumnContainer>
              <FooterLinsksGroupHeaderText>STRATEGY</FooterLinsksGroupHeaderText>
              {footerData?.strategyLinks?.map(
                (link, i) =>
                  link?.page &&
                  typeof link.page !== 'string' && (
                    <FooterLinksButton $buttonType={'primary'} href={getPayloadUrl(link?.page?.slug)} key={i}>
                      {link?.page?.name}
                    </FooterLinksButton>
                  ),
              )}
            </FooterLinksColumnContainer>
            <FooterLinksColumnContainer>
              <FooterLinsksGroupHeaderText>PRODUCT DESIGN</FooterLinsksGroupHeaderText>
              {footerData?.productDesignLinks?.map(
                (link, i) =>
                  link?.page &&
                  typeof link.page !== 'string' && (
                    <FooterLinksButton $buttonType={'primary'} href={getPayloadUrl(link?.page?.slug)} key={i}>
                      {link?.page?.name}
                    </FooterLinksButton>
                  ),
              )}
            </FooterLinksColumnContainer>
            <FooterLinksColumnContainer>
              <FooterLinsksGroupHeaderText>ENGINEERING</FooterLinsksGroupHeaderText>
              {footerData?.engineerLinks?.map(
                (link, i) =>
                  link?.page &&
                  typeof link.page !== 'string' && (
                    <FooterLinksButton $buttonType={'primary'} href={getPayloadUrl(link?.page?.slug)} key={i}>
                      {link?.page?.name}
                    </FooterLinksButton>
                  ),
              )}
            </FooterLinksColumnContainer>
          </FooterLinksContainer>
          <FooterSocialLinksContainer>
            <FooterDividerLine />
            <FooterSocialLinksContent>
              <FooterLinksBottomContainer>
                <FooterLinkAnchor href={'/'}>
                  <FooterLogoImage src={LogoWhite} alt="Vault Logo" />
                </FooterLinkAnchor>
                {footerData.privacyPolicy && typeof footerData.privacyPolicy !== 'string' && (
                  <FooterLinksGroup>
                    <FooterSocialLinksButton
                      $buttonType={'primary'}
                      href={getPayloadUrl(footerData.privacyPolicy.slug)}
                    >
                      {footerData.privacyPolicy.name}
                      <FooterComedyMaskSvg />
                    </FooterSocialLinksButton>
                    {'   '}
                    {f.copyright}
                  </FooterLinksGroup>
                )}
              </FooterLinksBottomContainer>
              <FooterLinksBottomContainer>
                {footerData?.contactInformation?.linkedInUrl && (
                  <FooterSocialLinksButton
                    inNewTab
                    $buttonType={'primary'}
                    href={footerData?.contactInformation?.linkedInUrl}
                  >
                    {f.social.linkedIn.text}
                    <BsLinkedin size={20} />
                  </FooterSocialLinksButton>
                )}
                <FooterSocialLinksButton
                  inNewTab
                  $buttonType={'primary'}
                  href={'mailto:' + footerData?.contactInformation?.email}
                >
                  {footerData?.contactInformation?.email}
                  <MdMailOutline size={20} />
                </FooterSocialLinksButton>
                <FooterSocialLinksMobileButton
                  inNewTab
                  $buttonType={'primary'}
                  href={'tel:+' + footerData?.contactInformation?.phone?.replace(/\D/g, '')}
                >
                  {footerData?.contactInformation?.phone}
                  <MdOutlineCall size={20} />
                </FooterSocialLinksMobileButton>
                <FooterSocialLinksMobileButton
                  inNewTab
                  $buttonType={'primary'}
                  href={'https://maps.google.com/?q=' + footerData?.contactInformation?.address}
                >
                  {footerData?.contactInformation?.address}
                  <GoLocation size={20} />
                </FooterSocialLinksMobileButton>
              </FooterLinksBottomContainer>
              <FooterLinksBottomContainer>
                <FooterSocialLinksDesktopButton
                  inNewTab
                  $buttonType={'primary'}
                  href={'tel:+' + footerData?.contactInformation?.phone?.replace(/\D/g, '')}
                >
                  {footerData?.contactInformation?.phone}
                  <MdOutlineCall size={20} />
                </FooterSocialLinksDesktopButton>
                <FooterSocialLinksDesktopButton
                  inNewTab
                  $buttonType={'primary'}
                  href={'https://maps.google.com/?q=' + footerData?.contactInformation?.address}
                >
                  {footerData?.contactInformation?.address}
                  <GoLocation size={20} />
                </FooterSocialLinksDesktopButton>
              </FooterLinksBottomContainer>
            </FooterSocialLinksContent>
          </FooterSocialLinksContainer>
        </FooterContainer>
      )}
    </FooterBackdrop>
  );
};

export default Footer;
